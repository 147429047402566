<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="productItem === undefined">
      <h4 class="alert-heading">حدث خطأ أثناء جلب بيانات المنتج</h4>
      <div class="alert-body">
        لم يتم العثور على المنتج .
        <b-link class="alert-link" :to="{ name: 'admin.products.index' }">قائمة المنتجات</b-link>
      </div>
    </b-alert>

    <b-row v-if="productItem" class="invoice-preview invoice1" id="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0 d-print-none">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title text-right">
                  <span class="invoice-number">{{ productItem.name }}</span>
                </h4>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing d-print-none" />

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="12" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-table
                  :items="productItem.order_items"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                  class="position-relative"
                >
                  <template #cell(order_id)="data">
                    <span class="text-nowrap">
                      #{{ data.item.order ? data.item.order.order_number : data.item.order_id }}
                    </span>
                  </template>
                  <template #cell(user)="data">
                    <span class="text-nowrap">
                      {{ data.item.order.contact ? data.item.order.contact.name : data.item.order.contact_id }}
                    </span>
                  </template>

                  <template #cell(unit_price)="data">
                    <span class="text-nowrap">
                      {{ __formatMoney(data.item.unit_price) }}
                    </span>
                  </template>

                  <template #cell(total)="data">
                    <span class="text-nowrap">
                      {{ __formatMoney(data.item.unit_price * data.item.quantity) }}
                    </span>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onMounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle
  },
  data() {
    return {
      number: "",
      numberRange: "",
      numberRegx: ""
    };
  },

  watch: {},
  mounted() {},
  created() {},
  destroyed() {},
  methods: {},
  setup() {
    const refFormObserver = ref(null);
    const tableColumns = [
      { key: "order_id", label: "order#", sortable: false },
      { key: "user", sortable: false },
      { key: "quantity", sortable: false },
      { key: "unit_price", sortable: false },
      { key: "total", sortable: false }
      // { key: 'actions' },
    ];

    // const user = computed(() => store.state.auth?.user);
    const productItem = ref(null);
    // const printInvoice = () => {
    //   window.print();
    // };
    onMounted(() => {
      //  productItem.value = store.state.orders.order;
    });

    store
      .dispatch("myproducts/fetchProductHistory", {
        id: router.currentRoute.params.id,
        params: {
          is_edit: true
        }
      })
      .then(response => {
        productItem.value = response.data;
      })
      .catch(error => {
        // if (error.response.status === 404) {
        //   //  productItem.value = undefined;
        // }
      });

    return {
      productItem,
      tableColumns
    };
  }
};
</script>

<style lang="scss">
@import "~@resources/scss/vue/libs/vue-select.scss";
@import "~@resources/scss/vue/libs/vue-flatpicker.scss";
.order-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@resources/scss/base/pages/app-invoice.scss";
@import "~@resources/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
